<template>
  <div class="user-page">
    <div class="user-center">
      <!-- Lightning版本相关列表类型 -->
      <p style="margin-bottom: 10px">{{ $t("label_related_list_types") }}：</p>
      <div style="display: flex">
        <div style="margin-right: 58px">
          <!-- 列表式 -->
          <el-radio v-model="userRadio" label="List">{{
            $t("label_tabular")
          }}</el-radio
          ><br />
          <img :src="list_img" style="margin-top: 15px" />
        </div>
        <div>
          <!-- 卡片式 -->
          <el-radio v-model="userRadio" label="Card">{{
            $t("label_card_type")
          }}</el-radio
          ><br />
          <img :src="card_img" style="margin-top: 15px" />
        </div>
      </div>
    </div>
    <div class="user_footer">
      <!-- 取消 保存 -->
      <el-button size="small">{{ $t("label.cancel") }}</el-button>
      <el-button size="small" type="primary" @click="onSubmit">{{
        $t("component_event_button_save")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import * as operationalTeamApi from "./api";

export default {
  name: "userPageSetup",
  data() {
    return {
      userRadio: "Card",
      list_img: require("@/assets/images/listCard/list_style.svg"),
      card_img: require("@/assets/images/listCard/card_style.svg"),
    };
  },
  created() {
    this.init();
  },
  mounted() {
    document.getElementById("lightning-loading").style.display = "none";
  },
  methods: {
    init() {
      operationalTeamApi.getUserInterface().then((res) => {
        this.userRadio = res.data.relatedListStyle;
      });
    },
    onSubmit() {
      operationalTeamApi
        .saveUserInterface({ relatedListStyle: this.userRadio })
        .then((res) => {
          if (res.result === true) {
            // 设置成功
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("label_set_successfully"),
            });
          } else {
            // 设置失败
            this.$message({
              showClose: true,
              type: "error",
              message: this.$i18n.t("label_setup_failed"),
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.user-page {
  position: relative;
  height: 100%;
}
.user-center {
  padding: 20px 10px;
  font-size: 12px;
  color: #080707;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #006dcc;
  background: #006dcc;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #006dcc;
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #080707;
}
.user_footer {
  border-top: 1px solid #dddbda;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 60px;
  padding: 10px 20px;
}
</style>
